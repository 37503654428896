// 获取任务列表
import { request } from './request'

export function getLotteryListRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/lottery/list',
    method: 'get',
    params
  })
}

// 删除任务
export function delLotteryRequest (id) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: `/lottery/${id}`,
    method: 'delete'
  })
}

// 添加任务
export function addLotteryRequest (info) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/lottery/add',
    method: 'post',
    data: { ...info }
  })
}

// 修改任务
export function updateLotteryRequest (info) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/lottery/update',
    method: 'put',
    data: { ...info }
  })
}

// 更改任务顺序
export function SortLotteryRequest (data) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/lottery/sort',
    method: 'post',
    data
  })
}

// 更改任务顺序
export function UpdateActivityRequest (data) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/activity/update',
    method: 'post',
    data
  })
}

export function GetActivityRequest (activityType) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/activity/detail',
    params: {
      activityType: activityType
    },
    method: 'get'
  })
}
