import { render, staticRenderFns } from "./LotteryRewardPane.vue?vue&type=template&id=6039ea23"
import script from "./LotteryRewardPane.vue?vue&type=script&lang=js"
export * from "./LotteryRewardPane.vue?vue&type=script&lang=js"
import style0 from "./LotteryRewardPane.vue?vue&type=style&index=0&id=6039ea23&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports