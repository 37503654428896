import { request } from './request'

// 获取优惠券列表
export function listCouponRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/coupon/list',
    method: 'get',
    params
  })
}

export function addCouponRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/coupon/add',
    method: 'post',
    data: { ...info }
  })
}

export function updateCouponRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/coupon/update',
    method: 'post',
    data: { ...info }
  })
}

export function getCouponRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/coupon/detail',
    method: 'get',
    params
  })
}

export function updateCouponStatusRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/coupon/update/status',
    method: 'post',
    data: { ...info }
  })
}
