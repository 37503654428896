<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>任务管理</template>
      <template v-slot:secondMenu>大转盘</template>
    </breadcrumb-nav>
    <el-card>
      <el-tabs>
        <el-tab-pane label="中奖记录">
          <lottery-record-pane></lottery-record-pane>
        </el-tab-pane>
        <el-tab-pane label="奖品设置">
          <lottery-reward-pane></lottery-reward-pane>
        </el-tab-pane>
        <el-tab-pane label="规则">
          <lottery-rule-pane></lottery-rule-pane>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>

import BreadcrumbNav from '../common/BreadcrumbNav'
import LotteryRewardPane from './lotteryChildComponents/LotteryRewardPane'
import LotteryRulePane from './lotteryChildComponents/LotteryRulePane'
import LotteryRecordPane from './lotteryChildComponents/LotteryRecordPane'

export default {
  name: 'Lottery',
  components: {
    LotteryRewardPane,
    LotteryRulePane,
    BreadcrumbNav,
    LotteryRecordPane
  },
  data () {
    return {
    }
  }
}
</script>

<style>
</style>
