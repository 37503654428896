<template>
  <div>
    <el-alert style="margin-bottom: 10px;" type="warning" :title=alertTitle :closable="false" show-icon></el-alert>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin: 10px 0px"
      @click="showDialog"
    >添加
    </el-button>
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="imageUrl" label="图标" width="80px">
        <template slot-scope="{ row }">
          <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称">
      </el-table-column>
      <el-table-column prop="grade" label="等级">
        <template slot-scope="scope">{{ scope.row.grade | gradeLabel }}</template>
      </el-table-column>
      <el-table-column prop="percent" label="中奖概率" width="100px">
      </el-table-column>
      <el-table-column prop="showText" label="展示概率" width="100px">
      </el-table-column>
      <el-table-column prop="backPercent" label="备选概率" width="100px">
      </el-table-column>
      <el-table-column prop="text" label="中奖文案">
      </el-table-column>
      <el-table-column prop="rewardType" label="奖励类型">
        <template slot-scope="scope">{{ scope.row.rewardType | rewardTypeLabel }}</template>
      </el-table-column>
      <!--<el-table-column prop="rewardVal" label="奖励最小值">
      </el-table-column>
      <el-table-column prop="rewardMax" label="奖励最大值">
      </el-table-column>-->
      <el-table-column prop="rewardDesc" label="奖励">
      </el-table-column>
      <!--<el-table-column prop="affim" label="点赞数量">
      </el-table-column>-->
      <el-table-column prop="share" label="一元砍刀数量">
      </el-table-column>
      <!--<el-table-column prop="logic" label="条件关系">
        <template slot-scope="scope">{{ scope.row.logic | logicLabel }}</template>
      </el-table-column>-->
      <el-table-column prop="prop" label="操作" width="200px">
        <template slot-scope="{row, $index}">
          <el-row>
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="updateLottery(row)"
            >修改</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="delLottery(row)"
            >删除</el-button
            >
          </el-row>
          <el-row>
            <el-button icon="el-icon-top" style="margin-left: 0;margin-top:10px;" type="success" size="mini" @click="upindex($index,row)">上移</el-button>
            <el-button icon="el-icon-bottom" type="success" size="mini"  @click="downindex($index,row)">下移</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="lotteryForm.id ? '修改奖项' : '添加奖项'"
      :visible.sync="dialogFormVisible"
    >
      <!-- form表单 :model属性，这个属性的作用是,把表单的数据收集到那个对象的身上 ，将来表单验证，也需要这个属性-->
      <el-form style="width: 80%" :model="lotteryForm" :rules="rules" ref="lotteryForm">
        <el-form-item label="名称" label-width="100px" prop="name">
          <el-input v-model="lotteryForm.name"></el-input>
        </el-form-item>
        <el-form-item label="描述" label-width="100px" prop="desc">
          <el-input v-model="lotteryForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="奖励等级" label-width="100px" prop="grade">
          <el-select v-model="lotteryForm.grade" style="width:100%" placeholder="请选择奖励等级">
            <el-option
              v-for="item in grades"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中奖概率" label-width="100px" prop="percent">
          <el-input type="Number" v-model.number="lotteryForm.percent"></el-input>
        </el-form-item>
        <el-form-item label="展示概率" label-width="100px" prop="showText">
          <el-input v-model="lotteryForm.showText"></el-input>
        </el-form-item>
        <el-form-item label="备选概率" label-width="100px" prop="backPercent">
          <el-input type="Number" v-model.number="lotteryForm.backPercent"></el-input>
        </el-form-item>
        <el-form-item label="中奖文案" label-width="100px" prop="text">
          <el-input v-model="lotteryForm.text"></el-input>
        </el-form-item>
        <el-form-item label="奖励类型" label-width="100px" prop="rewardType">
          <el-select v-model="lotteryForm.rewardType" style="width:100%" placeholder="请选择奖励类型">
            <el-option
              v-for="item in rewardTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="奖励最小值" label-width="100px" prop="rewardVal" v-if="lotteryForm.rewardType!=2">
          <el-input type="Number" v-model.number="lotteryForm.rewardVal"></el-input>
        </el-form-item>
        <el-form-item label="奖励最大值" label-width="100px" prop="rewardMax" v-if="lotteryForm.rewardType!=2">
          <el-input type="Number" v-model.number="lotteryForm.rewardMax"></el-input>
        </el-form-item>
        <!--<el-form-item label="点赞数量" label-width="100px" prop="affim">
          <el-input type="Number" v-model.number="lotteryForm.affim"></el-input>
        </el-form-item>-->
        <el-form-item label="砍刀数量" label-width="100px" prop="share" v-if="lotteryForm.rewardType!=2">
          <el-input type="Number" v-model.number="lotteryForm.share"></el-input>
        </el-form-item>
        <el-form-item label="优惠券" label-width="100px" prop="logic" v-if="lotteryForm.rewardType==2">
          <el-select v-model="lotteryForm.couponId" style="width:100%" placeholder="请选择优惠券">
            <el-option
              v-for="item in couponList"
              :key="item.couponId"
              :label="item.label"
              :value="item.couponId">
            </el-option>
          </el-select>
        </el-form-item>
        <!--<el-form-item label="条件关系" label-width="100px" prop="logic">
          <el-select v-model="lotteryForm.logic" style="width:100%" placeholder="请选择条件关系">
            <el-option
              v-for="item in logicTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item label="图标" label-width="100px" prop="icon">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :headers="imgUploadHeaders"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdateLottery"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {
  updateLotteryRequest,
  addLotteryRequest,
  getLotteryListRequest,
  delLotteryRequest,
  SortLotteryRequest
} from '../../../network/lottery'
import { listCouponRequest } from '../../../network/coupon'

export default {
  name: 'LotteryRewardPane',
  filters: {
    gradeLabel (value) {
      switch (value) {
        case 1:
          return '一等奖'
        case 2:
          return '二等奖'
        case 3:
          return '三等奖'
        case 4:
          return '普通奖'
        case 5:
          return '谢谢参与'
      }
    },
    rewardTypeLabel (value) {
      switch (value) {
        case 1:
          return '金币'
        case 2:
          return '优惠券'
        case 3:
          return '人民币'
      }
    },
    logicLabel (value) {
      switch (value) {
        case 0:
          return '且'
        case 1:
          return '或'
      }
    }
  },
  data () {
    return {
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: '',
      disabled: false,
      // 收集签到规则信息
      lotteryForm: {
        icon: '',
        name: '',
        desc: '',
        grade: '',
        percent: '',
        backPercent: '',
        text: '',
        rewardType: '',
        rewardVal: '',
        rewardMax: '',
        affim: '',
        share: '',
        logic: 0,
        isNotify: true,
        showText: '',
        playType: 1,
        couponId: 0
      },
      // 表单验证规则
      rules: {
        // require:必须要校验字段（前面五角星有关系）  message 提示信息    trigger:用户行为设置（事件的设置:blur、change）
        name: [
          { required: true, message: '请输入奖项名称', trigger: 'blur' }
        ],
        rewardVal: [
          { required: true, message: '请输入奖励最小值', trigger: 'blur' }
        ],
        rewardMax: [
          { required: true, message: '请输入奖励最大值', trigger: 'blur' }
        ],
        percent: [
          { required: true, message: '请输入中奖概率', trigger: 'blur' }
        ],
        backPercent: [
          { required: true, message: '请输入备选概率', trigger: 'blur' }
        ],
        showText: [
          { required: true, message: '请输入展示概率', trigger: 'blur' }
        ],
        rewardType: [
          { required: true, message: '请输入奖励类型', trigger: 'change' }
        ],
        grade: [
          { required: true, message: '请输入奖励等级', trigger: 'change' }
        ],
        icon: [{ required: true, message: '请选择任务图标' }]
      },
      // 任务类型
      rewardTypes: [{
        label: '金币',
        value: 1
      }, {
        label: '优惠券',
        value: 2
      }, {
        label: '人民币',
        value: 3
      }],
      // 奖励等级
      grades: [{
        label: '一等奖',
        value: 1
      }, {
        label: '二等奖',
        value: 2
      }, {
        label: '三等奖',
        value: 3
      }, {
        label: '普通奖',
        value: 4
      }, {
        label: '谢谢参与',
        value: 5
      }],
      logicTypes: [
        {
          label: '且',
          value: 0
        }, {
          label: '或',
          value: 1
        }
      ],
      totalPercent: 0,
      backTotalPercent: 0,
      alertTitlePrefix: '注意：请设置6个商品用于抽奖，抽奖概率单位为：万分之一，当前总概率为：',
      backTitlePrefix: '；备选总概率为：',
      alertTitle: '',
      couponList: []
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getLotteryList()
    this.getRedCouponList()
  },
  methods: {
    getRedCouponList () {
      listCouponRequest({ sence: 2 }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取列表失败', 'error')
        }
        this.couponList = result.data.list
        for (const item of this.couponList) {
          item.label = '满' + item.minPoint + '减' + item.reduceAmount
        }
      })
    },
    getLotteryList () {
      getLotteryListRequest({ playType: 1 }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取规则列表失败', 'error')
        }
        this.list = result.data.list
        this.totalPercent = 0
        this.backTotalPercent = 0
        for (const item of this.list) {
          item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.icon
          item.isNotify = item.isNotify === 1
          this.totalPercent += item.percent
          this.backTotalPercent += item.backPercent
          item.rewardDesc = item.rewardVal + '~' + item.rewardMax
        }
        this.alertTitle = this.alertTitlePrefix + this.totalPercent + this.backTitlePrefix + this.backTotalPercent
        console.log('getLotteryList list:', this.list)
        this.total = result.data.total
      })
    },
    updateLottery (row) {
      this.dialogFormVisible = true
      // 将已有的规则信息赋值给ruleForm进行展示
      // 将服务器返回规则的信息，直接赋值给了ruleForm进行展示。
      // 也就是ruleForm存储即为服务器返回规则信息
      this.lotteryForm = { ...row }
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.lotteryForm.icon
    },
    delLottery (row) {
      console.log('del row:', row)
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delLotteryRequest(row.id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除失败', 'error')
          }
          this.alertMessage('删除成功', 'success')
          this.getLotteryList()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    showDialog () {
      this.dialogFormVisible = true
      this.lotteryForm = { icon: '', name: '', desc: '', text: '', grade: '', percent: '', backPercent: '', rewardType: '', rewardVal: '', rewardMax: '', isNotify: true, affim: '', share: '', logic: 0, showText: '', playType: 1 }
      this.imageUrl = ''
    },
    updateLottery2Srv () {
      updateLotteryRequest(this.lotteryForm).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改奖项失败', 'error')
        }
        this.alertMessage('修改奖项成功', 'success')
        this.getLotteryList()
      })
    },
    addLottery2Srv () {
      addLotteryRequest(this.lotteryForm).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加奖项失败', 'error')
        }
        this.alertMessage('添加奖项成功', 'success')
        this.getLotteryList()
      })
    },
    addOrUpdateLottery () {
      this.$refs.lotteryForm.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        this.lotteryForm.isNotify = this.lotteryForm.isNotify ? 1 : 0
        console.log('this.lotteryForm:', this.lotteryForm)
        // return
        // eslint-disable-next-line no-unreachable
        if (this.lotteryForm.id) {
          this.updateLottery2Srv()
        } else {
          this.addLottery2Srv()
        }
      })
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.lotteryForm.icon = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.lotteryForm.icon
    },
    handleImgRemove (file) {
      this.lotteryForm.icon = ''
      this.imageUrl = ''
    },
    upindex (index, row) {
      if (index > 0) {
        const curData = this.list[index]
        const upData = this.list[index - 1]
        const sortLottery = []
        sortLottery.push({ id: curData.id, sortNum: upData.sortNum })
        sortLottery.push({ id: upData.id, sortNum: curData.sortNum })
        SortLotteryRequest({ list: sortLottery }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('上移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = upData.sortNum
          upData.sortNum = curSortNum
          this.list.splice(index - 1, 1)
          this.list.splice(index, 0, upData)
        })
      } else {
        this.$message.warning('已经是第一条了！')
        return false
      }
    },
    downindex (index, row) {
      if (index + 1 === this.list.length) {
        this.$message.warning('已经是最后一条了！')
        return false
      } else {
        const downData = this.list[index + 1]
        const curData = this.list[index]
        const sortLottery = []
        sortLottery.push({ id: curData.id, sortNum: downData.sortNum })
        sortLottery.push({ id: downData.id, sortNum: curData.sortNum })
        SortLotteryRequest({ list: sortLottery }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('下移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = downData.sortNum
          downData.sortNum = curSortNum
          this.list.splice(index + 1, 1)
          this.list.splice(index, 0, downData)
        })
      }
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
